import request from './request';


const create = async (data) => {
  try {
    console.log(data);
    const response = await request.post('/goals', data);
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const read = async () => {
  try {
    const response = await request.get('/goals');
    console.log(response);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};


const update = async (data) => {
  try {
    const { timestamp, ...body } = data;
    const response = await request.patch(`/goals/${timestamp}`, body);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const remove = async (data) => {
  try {
    const { timestamp } = data;
    const response = await request.delete(`/goals/${timestamp}`);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export default {
  create,
  read,
  update,
  remove,
}
