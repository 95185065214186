<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-container>
        <v-row class="mb-3">
            <v-col>
                <h1>Goals</h1>
            </v-col>
            <v-col class="text-right">
                <v-btn color="primary" fab @click="showGoalCreationForm = true">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-expand-transition>
            <v-card v-show="showGoalCreationForm" class="mb-5 pb-3" elevation="6" xl="6" :loading="newGoalFormLoading">
                <v-form ref="newGoalForm" v-model="newGoalFormValid"
                        @submit.prevent="addGoalHandler"
                        lazy-validation
                >
                    <v-card-title class="justify-center">Add New Goal</v-card-title>
                    <v-card-text>
                        <v-row justify="center">
                            <v-col cols="12" xl="4" lg="6">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                                solo
                                                flat
                                                v-model="newGoal.name"
                                                :counter="50"
                                                label="Name"
                                                hint="Required"
                                                outlined
                                                :rules="newGoalFormRules.name"
                                                placeholder="Pay loan for BMW M3"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                                solo
                                                flat
                                                v-model="newGoal.description"
                                                name="description"
                                                :rules="newGoalFormRules.description"
                                                :counter="200"
                                                label="Description"
                                                outlined
                                                placeholder="Pay at least $3000 every month for a year"
                                                rows="3"
                                                hint="Optional"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                                solo
                                                flat
                                                v-model="newGoal.prefix"
                                                label="Prefix"
                                                outlined
                                                placeholder="$"
                                                :rules="newGoalFormRules.prefix"
                                                hint="Optional"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                                solo
                                                flat
                                                v-model.number="newGoal.startValue"
                                                label="Current Value"
                                                placeholder="0"
                                                value="0"
                                                :rules="newGoalFormRules.numberValue"
                                                type="number"
                                                hint="Required"
                                                outlined
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                                solo
                                                flat
                                                v-model.number="newGoal.targetValue"
                                                label="Target Value"
                                                placeholder="36000"
                                                :rules="newGoalFormRules.numberValue"
                                                type="number"
                                                hint="Required"
                                                outlined
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                                solo
                                                flat
                                                v-model="newGoal.suffix"
                                                label="Suffix"
                                                :rules="newGoalFormRules.suffix"
                                                outlined
                                                placeholder="kg"
                                                hint="Optional"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit">Create</v-btn>
                        <v-btn icon absolute top right @click.stop="closeCreationHandler"><v-icon>mdi-close</v-icon></v-btn>
                        <v-dialog v-model="cancelCreationDialog" width="450">
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-3" dark>
                                    <h6>Attention!</h6>
                                </v-card-title>
                                <v-card-text class="py-3">
                                    Are you sure?
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="cancelCreationDialog = false">
                                        No
                                    </v-btn>
                                    <v-btn text color="error" @click="clearGoalHandler">
                                        Yes
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-expand-transition>
        <v-data-iterator
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :search="search"
                :sort-by="sortBy.toLowerCase()"
                :sort-desc="sortDesc"
                :loading="goalsListLoading"
                no-data-text="You don't have any goals yet. You can create one using the plus (+) button on the top."
                item-key="itemId"
                :single-expand="true"
                hide-default-footer
        >
            <template v-slot:header>
                <v-toolbar
                        dark
                        color="primary"
                        class="mb-1"
                >
                    <v-text-field
                            v-model="search"
                            clearable
                            flat
                            solo-inverted
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            label="Search"
                    ></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select
                                v-model="sortBy"
                                flat
                                solo-inverted
                                hide-details
                                :items="keys"
                                prepend-inner-icon="mdi-magnify"
                                label="Sort by"
                        ></v-select>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                                v-model="sortDesc"
                                mandatory
                        >
                            <v-btn
                                    large
                                    depressed
                                    color="primary"
                                    :value="false"
                            >
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn
                                    large
                                    depressed
                                    color="primary"
                                    :value="true"
                            >
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </template>
                </v-toolbar>
            </template>

            <template v-slot:item="{ item, isExpanded, expand }">
                <v-row>
                    <v-col>
                        <Goal :goal-data="item" :is-expanded="isExpanded" :expand-action="expand"/>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-row
                        class="mt-2 px-3"
                        align="center"
                        justify="center"
                >
                    <span class="grey--text">Items per page</span>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    dark
                                    text
                                    color="primary"
                                    class="ml-2"
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                {{ itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                    v-for="(number, index) in itemsPerPageArray"
                                    :key="index"
                                    @click="updateItemsPerPage(number)"
                            >
                                <v-list-item-title>{{ number }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>

                    <span class="mr-4 grey--text">Page {{ page }} of {{ numberOfPages }}</span>
                    <v-btn
                            fab
                            dark
                            small
                            color="primary"
                            class="mr-1"
                            @click="formerPage"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                            fab
                            dark
                            small
                            color="primary"
                            class="ml-1"
                            @click="nextPage"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-row>
            </template>
            <template v-slot:loading>
                <v-row justify="center" class="py-2">
                    <v-col cols="12" class="text-center pb-0">
                        <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-col>
                    <v-col cols="12" class="text-center">Loading...</v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <StatusMessage></StatusMessage>
    </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Goal from './Goal';
  import StatusMessage from "../common/StatusMessage";

  const defaultForm = () => ({startValue: 0})
  export default {
    name: "Main",
    metaInfo: {
      title: 'Dashboard',
    },
    components: {StatusMessage, Goal },
    data () {
      return {
        goalsListLoading: false,
        showGoalCreationForm: false,
        newGoal: defaultForm(),
        newGoalFormValid: true,
        newGoalFormRules: {
          name: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 50) || 'Name must be less than 50 characters',
          ],
          description: [
            v => (!v || (v && v.length <= 200)) || 'Description must be less than 200 characters',
          ],
          numberValue: [
            v => typeof v === 'number' || 'Field is required',
            v => (typeof v === 'number' && /^\d+$/.test(v)) || 'Target must be a number',
          ],
          prefix: [
            v => (!v || (v && v.length <= 10)) || 'Max 10 chars',
          ],
          suffix: [
            v => (!v || (v && v.length <= 10)) || 'Max 10 chars',
          ],
        },
        newGoalFormLoading: false,
        itemsPerPageArray: [10, 20, 50],
        search: '',
        filter: {},
        sortDesc: true,
        page: 1,
        itemsPerPage: 10,
        sortBy: 'timestamp',
        keys: [{
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Created Date',
          value: 'timestamp'
        }],
        cancelCreationDialog: false,
      }
    },
    async mounted() {
      try {
        this.goalsListLoading = true;
        await this.getAllGoals();
        this.goalsListLoading = false;
      } catch (err) {
        this.$store.dispatch('statusMessage', {message: 'Ooops... Something went wrong. Try again later', type: 'error'});
        this.goalsListLoading = false;
      }
    },
    computed: {
      ...mapState({
        items: (state) => state.goal.list,
      }),
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key.value !== 'name')
      },
    },
    methods: {
      ...mapActions({
        createGoal: 'goal/create',
        getAllGoals: 'goal/read',
      }),
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      async addGoalHandler () {
        const valid = this.$refs.newGoalForm.validate();
        if (!valid) {
          return;
        }
        this.newGoalFormLoading = true;
        try {
          await this.createGoal(this.newGoal);
          this.$store.dispatch('statusMessage', {message: 'Goal created successfully'});
          this.showGoalCreationForm = false;
          this.newGoalFormLoading = false;
          this.newGoal = defaultForm();
          this.$refs.newGoalForm.resetValidation();
        } catch (error) {
          this.$store.dispatch('statusMessage', {message: 'Ooops... Something went wrong. Try again later', type: 'error'});
          this.newGoalFormLoading = false;
        }
      },
      closeCreationHandler() {
        if (Object.values(this.newGoal).filter(v => !!v).length) {
          this.cancelCreationDialog = true;
        } else {
          this.showGoalCreationForm = false;
        }
      },
      clearGoalHandler () {
        this.newGoal = defaultForm();
        this.showGoalCreationForm = false;
        this.cancelCreationDialog = false;
        this.$refs.newGoalForm.resetValidation();
      }
    },
  }
</script>

<style scoped>

</style>
