import goalResourceApi from '../api/goalResourceApi';

export default {
  state: () => ({
    list: [
    ],
    count: 0,
  }),
  namespaced: true,
  mutations: {
    push: (state, newGoal) => {
      state.list.push({...newGoal});
    },
    update: (state, data) => {
      const foundItemIndex = state.list.findIndex((i) => i.sk === data.sk);
      const foundItem = state.list[foundItemIndex];
      Object.assign(foundItem, data);
      state.list.splice(foundItemIndex, 1, foundItem);
    },
    remove: (state, data) => {
      const foundItemIndex = state.list.findIndex((i) => i.sk === data.sk);
      state.list.splice(foundItemIndex, 1);
    },
    setCollection: (state, goals) => {
      state.list = goals;
    },
  },
  actions: {
    async read ({commit}) {
      // call api
      const result = await goalResourceApi.read();
      commit('setCollection', result.data);
    },
    async create ({ commit }, data) {
      // call api
      const goalData = await goalResourceApi.create(data);
      commit('push', goalData);
    },
    async update ({ commit }, data) {
      // call api
      await goalResourceApi.update(data);
      commit('update', data);
    },
    async remove ({ commit }, data) {
      // call api
      await goalResourceApi.remove(data);
      commit('remove', data);
    }
  }
}
