<template>
  <v-app>
    <v-overlay :value="overlay">
      <v-progress-circular
              indeterminate
              size="64"
      ></v-progress-circular>
    </v-overlay>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'My Milestone',
    // all titles will be injected into this template
    titleTemplate: '%s | My Milestone'
  },
  data() {
    return {
      overlay: true,
    }
  },
  mounted() {
    this.overlay = false;
  },
  components: {
  },
};
</script>
