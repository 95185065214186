import goalItemResourceApi from '../api/goalItemResourceApi';

export default {
  state: () => ({
    goalTimestamp: null,
    list: [
    ],
    count: 0,
  }),
  namespaced: true,
  mutations: {
    push: (state, newGoal) => {
      // use unshift to add new value to the top
      state.list.unshift({...newGoal});
    },
    setCollection: (state, goalItems) => {
      state.list = goalItems;
    },
    setGoal: (state, goalTimestamp) => {
      if (state.goalTimestamp !== goalTimestamp) {
        state.goalTimestamp = goalTimestamp;
      }
    },
  },
  actions: {
    async read ({commit}, data) {
      // call api
      commit('setGoal', data.goalTimestamp);
      const result = await goalItemResourceApi.read(data);
      console.log(result);
      commit('setCollection', result.data);
    },
    async create ({commit, state, dispatch}, data) {
      console.log(data);
      // call api
      const { goal, goalItem } = await goalItemResourceApi.create(data);
      console.log(goal);
      //
      commit('goal/update', goal, { root: true });
      if (state.goalTimestamp) {
        commit('push', goalItem);
      } else {
        await dispatch('read', { goalTimestamp: goal.timestamp });
      }
    },
  }
}
