<template>
  <div>
    <v-form ref="forgot" v-if="showForgotForm" @submit.prevent="forgotFormHandler">
      <h2 class="text-center">Forgot password</h2>
      <p class="text-center">You'll receive email with the password reset link.</p>
      <v-alert :type="resultMessageType" v-if="resultMessage">{{ resultMessage }}</v-alert>
      <v-text-field outlined name="username" label="E-mail" v-model="username"></v-text-field>
      <v-row class="mb-2" justify="center">
        <v-btn :loading="loading" type="submit" color="primary">Reset Password</v-btn>
      </v-row>
    </v-form>
    <div v-if="showReset">
      <h2 class="text-center">Reset password</h2>
      <v-alert :type="resultMessageType" v-if="resultMessage">{{ resultMessage }}</v-alert>
      <v-form ref="reset" v-if="showResetForm" @submit.prevent="resetFormHandler">
        <v-alert type="info">We sent an e-mail with password reset code code to {{ this.username }}.<br>Please enter the code from the e-mail bellow</v-alert>
        <v-text-field outlined name="verificationCode" :rules="verificationCodeRules" label="Code from E-Mail" v-model="verificationCode"></v-text-field>
        <v-text-field outlined name="password" :rules="passwordRules" label="New Password" type="password"  v-model="password"></v-text-field>
        <v-text-field outlined name="repeatPassword" :rules="[passwordConfirmationRule]" label="Repeat Password" type="password"  v-model="repeatPassword"></v-text-field>
        <v-row class="mb-2" justify="center">
          <v-btn :loading="loading" type="submit" color="primary">Reset Password</v-btn>
        </v-row>
      </v-form>
      <v-row mt="2" v-else justify="center">
        <router-link class="mx-2" to="/auth/login">Login</router-link>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { Auth } from  '@/plugins/amplify';

  export default {
    name: 'ForgotPassword',
    metaInfo: {
      title: 'Password Reset',
    },
    data: () => ({
        resultMessage: '',
        resultMessageType: 'error',
        username: '',
        usernameRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        loading: false,
        showForgotForm: true,
        showResetForm: false,
        showReset: false,
        password: '',
        passwordRules: [
          v => !!v || 'Pasword is required',
          v => v.length >= 8 || 'Pasword must be at least 8 characters',
          v => /[a-z]/.test(v) || 'Pasword should contain lowercase letter',
          v => /[A-Z]/.test(v) || 'Pasword should contain uppercase letter',
          v => /[0-9]/.test(v) || 'Pasword should contain number',
        ],
        repeatPassword: '',
        verificationCode: '',
        verificationCodeRules: [
          v => !!v || 'Code is required',
          v => v.length === 6 || 'Code must be 6 characters',
          v => /^[0-9]{6,6}$/.test(v) || 'Pasword must only contain numbers',
        ],
    }),
    computed: {
      passwordConfirmationRule() {
        return () => (this.password === this.repeatPassword) || 'Password must match'
      },
    },
    methods: {
        ...mapActions({ setUser: 'user/setInfo' }),
        async forgotFormHandler() {
          if (!this.$refs.forgot.validate()) {
            return false;
          }
          this.resultMessage = '';
          this.loading = true;
          try {
              const data = await Auth.forgotPassword(this.username);
              console.log(data);
              this.showForgotForm = false;
              this.showReset = true;
              this.showResetForm = true;
          } catch (error) {
              console.log('Error', error);
              this.resultMessage = error.message;
              this.resultMessageType = 'error';
          }
          finally {
              this.loading = false;
          }
        },
        async resetFormHandler() {
          this.resultMessage = '';
          this.loading = true;
          if (!this.$refs.reset.validate()) {
            return false;
          }
          try {
              const data = await Auth.forgotPasswordSubmit(this.username, this.verificationCode, this.password);
              console.log(data);
              this.resultMessage = 'Your password has been restored';
              this.resultMessageType = 'success';
              this.showResetForm = false;
            }
            catch (error) {
                console.log('Error', error);
                this.resultMessage = error.message;
                this.resultMessageType = 'error';
            }
            finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>