import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from '@/plugins/amplify';
import Main from '../components/dashboard/Main.vue';
import Home from '../components/Home.vue';
import HelloWorld from "../components/HelloWorld";
import ErrorComponent from '../components/ErrorComponent';
import MainLayout from '../components/layouts/MainLayout';
import AuthLayout from '@/components/layouts/AuthLayout';
import Login from '@/components/auth/Login';
import SignUp from '@/components/auth/SignUp';
import ForgotPassword from '@/components/auth/ForgotPassword';
import permissionGuard from './permissionGuard';

Vue.use(VueRouter);
const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    children: [
      {
        path: '/auth/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/auth/signup',
        name: 'SignUp',
        component: SignUp
      },
      {
        path: '/auth/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: '/auth/logout', ErrorComponent, async beforeEnter(to, from, next){
          await Auth.signOut();
          next({
            path: '/auth/login'
          });
        }
      },
    ]
  },
  { path: '/', component: Home },
  { path: '/about', component: HelloWorld },
  {
    path: '/dashboard',
    name: '',
    meta: {
      checkPermissions: true,
    },
    component: MainLayout,
    children: [{
      path: '/dashboard/',
      name: 'dashboard',
      component: Main
    }]
  },
  {
    path: '/404', component: ErrorComponent
  },
  {
    path: '*', component: ErrorComponent
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(permissionGuard);

export default router
