import store from '../store';
import { Auth } from '@/plugins/amplify';
// import auth from '../auth';
// import UserInfoApi from '../api/userInfoApi';

export default async (to, from, next) => {
  let isAuthentificated = false;
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    console.log("🚀 ~ file: permissionGuard.js:8 ~ cognitoUser:", cognitoUser)
    console.log('check');
    store.dispatch('user/setLoggedIn', true);
    store.dispatch('user/setInfo', cognitoUser.attributes);
    isAuthentificated = true;
  } catch (err) {
    console.log('not authentificated');
    isAuthentificated = false;
  }

  if(!to.matched.some((record) => record.meta.checkPermissions)) {
    return next();
  }
  if (to.name !== 'LogIn' && !isAuthentificated) {
    store.dispatch('user/setLoggedIn', false)
    next({
      path: '/auth/login',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
}
