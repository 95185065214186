<template>
  <div>
    <v-app-bar color="#f6f6f6">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
                alt="My Milestone logo"
                class="shrink mr-2"
                contain
                src="@/assets/logo.svg"
                transition="scale-transition"
                width="30"
        />
        <h2 class="black--text">My Milestone<small class="grey--text">.org</small></h2>
      </router-link>
    </v-app-bar>

    <v-main class="py-4 mb-16">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="8">
            <router-view :key="$route.path"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer padless absolute>
        <v-col class="text-center" cols="12">
            © 2022 - {{ new Date().getFullYear() }} — <strong>my-milestone.org</strong> created by <a target="_blank" href="https://www.linkedin.com/in/leonid-serdiuk-dev">Leonid Serdiuk</a>
        </v-col>
    </v-footer>
  </div>
</template>

<script>
  export default {
    name: 'AuthLayout',
  }
</script>

<style scoped>

</style>
