export default {
  state: () => ({
    info: {},
    loggedIn: false,
    loadingState: true,
    errorLoadingState: false,
  }),
  namespaced: true,
  mutations: {
    setInfo: (state, userInfo) => {
      state.info = userInfo;
    },
    setLoggedIn: (state, newValue) => {
      state.loggedIn = newValue;
    },
    setLoggedOut: (state) => {
      state.loggedIn = false;
      state.info = {};
    }
  },
  actions: {
    setInfo: ({commit}, userInfo) => {
      commit('setInfo', userInfo);
    },
    setLoggedIn: ({commit}, newValue) => {
      commit('setLoggedIn', newValue);
    },
    setLoggedOut: ({commit}) => {
      commit('setLoggedOut');
    }
  }
}
