import axios from 'axios';
// import { DateTime } from 'luxon';
import { Auth } from '@/plugins/amplify';
// import jwt from 'jsonwebtoken';
// import auth from '../auth';

const requestInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});


const getToken = async () => {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

// const refresh = async () => {
//   const refreshToken = auth.auth.getSignInUserSession().getRefreshToken().refreshToken;
//   return new Promise((resolve) => {
//     auth.auth.refreshSession(refreshToken);
//     const interval = setInterval(() => {
//       if(refreshToken !== auth.auth.getSignInUserSession().getRefreshToken().refreshToken) {
//         clearInterval(interval);
//         resolve();
//       }
//     }, 100)
//   })
// };

requestInstance.interceptors.request.use(async (config) => {
  const jwtToken = await getToken();
  console.log("🚀 ~ file: request.js:31 ~ requestInstance.interceptors.request.use ~ jwtToken:", jwtToken)
  Object.assign(config.headers, {
    'Authorization': 'Bearer '+ jwtToken
  });
  
  return config;
});

export default requestInstance;
