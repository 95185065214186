import request from './request';


const create = async (data) => {
  try {
    console.log(data);
    const { goalTimestamp, ...itemData } = data;
    console.log(itemData);
    const response = await request.post(`/goal-items/${goalTimestamp}`, itemData);
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const read = async (data) => {
  try {
    const { goalTimestamp } = data;
    const response = await request.get(`/goal-items/${goalTimestamp}`);
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};


const update = async (data) => {
  console.log(data);
};

const remove = async (id) => {
  console.log(id);
};

export default {
  create,
  read,
  update,
  remove,
}
