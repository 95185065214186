<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="overflow-hidden">
    <v-app-bar elevate-on-scroll fixed scroll-target="#scrolling-techniques-7" :color="barbBg">
      <v-container>
        <v-row align="center">
          <router-link to="/" class="d-flex align-center text-decoration-none">
              <v-img
                      alt="My Milestone logo"
                      class="shrink mr-2"
                      contain
                      src="@/assets/logo.svg"
                      transition="scale-transition"
                      width="30"
              />
              <h2 :class="`d-none d-sm-block app-bar_title ${textColor}--text`">My Milestone<small class="app-bar_title_tld">.org</small></h2>
          </router-link>
          <v-spacer></v-spacer>
          <router-link to="/auth/signup">
              <v-btn :color="buttonColor">Sign Up</v-btn>
          </router-link>
          <router-link class="ml-3" to="/dashboard">
              <v-btn outlined :color="buttonColor">Login</v-btn>
          </router-link>
        </v-row>
      </v-container>
    </v-app-bar>
    <div class="home-banner">
      <div class="home-banner_overlay">
      </div>
      <div class="home-banner_content d-flex align-center">
        <v-container>
          <v-row align="center" class="white--text home-banner_content_text">
            <v-col cols="12" md="6">
              <span>Set Your Goals.</span><br>
              <span>Track Your Progress.</span><br>
              <span>Repeat.</span>
            </v-col>
            <v-col cols="12" md="6">
              <h1>My Milestone</h1>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-main class="main-content">
      <v-container class="py-12">
          <v-row align="center">
            <v-col cols="12" md="6" class="text-center text-md-left">
              <p class="text-description">
                Supercharge your ambitions with my goal-tracking platform. Set, monitor, and achieve your objectives effortlessly. Unleash your potential, track progress, and celebrate success. Your personalized path to greatness awaits – begin your goal-tracking journey now!
              </p>
              <router-link to="/auth/signup">
                <v-btn outlined :color="buttonColor">Lets Go</v-btn>
              </router-link>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <v-img src="../assets/app-window.jpeg" alt="My-Milstone.org application window"></v-img>
              </div>
            </v-col>
          </v-row>
      </v-container>
      <v-container class="bg-primary py-12" fluid>
        <v-carousel
          v-model="slidesModel"
          hide-delimiters
          show-arrows-on-hover
          cycle
          height="40"
        >
          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
          >
            <v-row class="fill-height" align="center" justify="center">
              <div class="py-2 text-h4 white--text">
                {{ slide }}
              </div>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-container>
      <v-container class="py-12">
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <p>I value your input! Share your feedback on My-Milesone.org, suggest ideas for improvement, and report any bugs you encounter. Your contributions help me enhance the platform. Lets build it together!</p>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <v-img src="../assets/support-image.jpg" alt="support or bug report"></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <h2 class="mb-3 text-center">Try It And Give Me Your Feedback</h2>
              <v-alert v-if="errorMessage" :type="errorMessageType">{{ errorMessage }}</v-alert>
              <v-form ref="contact" @submit.prevent="contactFormHandler">
                <v-text-field label="Subject" solo outlined flat name="subject" v-model="subject" placeholder="Improvement Suggestion / Bug Report"></v-text-field>
                <v-textarea rows="2" solo outlined flat v-model="message" name="message"
                  label="Message"
                ></v-textarea>
                <v-row justify="end">
                  <v-btn :loading="loading" type="submit" color="mx-3 primary">Send</v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
      </v-container>
    </v-main>
    <v-footer padless>
        <v-col class="text-center" cols="12">
            © 2022 - {{ new Date().getFullYear() }} — <strong>my-milestone.org</strong> created by <a target="_blank" href="https://www.linkedin.com/in/leonid-serdiuk-dev">Leonid Serdiuk</a>
        </v-col>
    </v-footer>
  </div>
</template>

<script>
  import contactApi from '../api/contactApi';

  export default {
    name: "Home",
    metaInfo: {
      title: 'My Milestone',
      titleTemplate: null,
      meta: [
        { name: 'description', content: 'Supercharge your ambitions with my goal-tracking platform. Set, monitor, and achieve your objectives effortlessly. Unleash your potential, track progress, and celebrate success. Your personalized path to greatness awaits – begin your goal-tracking journey now!' },
        { name: 'keywords', content: 'My Milestone, goal-tracking, personal growth, dream achievement, progress log' }
      ]
    },
    data () {
      return {
        barbBg: 'transparent',
        buttonColor: 'white',
        textColor: 'white',
        slides: [
          'Empower Your Ambitions',
          'Elevate Your Progress',
          'Unleash Your Potential',
          'Transform Dreams into Reality',
          'Do It Your Way',
        ],
        slidesModel: 0,
        subject: '',
        message: '',
        loading: false,
        errorMessage: '',
        errorMessageType: 'error',
      }
    },
    async mounted() {
      window.onscroll = () => {
        this.changeColor();
      };
    },
    computed: {
    },
    methods: {
      changeColor() {
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          this.barbBg = 'white';
          this.buttonColor = 'primary';
          this.textColor = 'black';
        } else {
          this.barbBg = 'transparent';
          this.buttonColor = 'white';
          this.textColor = 'white';
        }
      },
      async contactFormHandler() {
        this.loading = true;
        try {
          const res = await contactApi.send({subject: this.subject, message: this.message});
          console.log(res);
          this.errorMessage = 'Thank You!';
          this.errorMessageType = 'success';
        }
        catch (error) {
            console.log('Error', error);
            this.errorMessage = error.message;
            this.errorMessageType = 'error';
        }
        finally {
            this.loading = false;
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  // .app-bar {
  //   &.v-app-bar.v-toolbar.v-sheet {
  //     background-color: transparent;
  //   }
  //   .app-bar_title {
  //     color: #f5f5f5;
  //   }
  //   &.v-app-bar--is-scrolled {
  //     &.v-app-bar.v-toolbar.v-sheet {
  //       background-color: #f5f5f5;
  //     }
  //     .app-bar_title {
  //       color: #000;
  //     }
  //   }
  // }
  .bg-primary {
    background-color: var(--v-primary-base);
  }
  .home-banner {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url('../assets/home-banner.jpg');
    position: relative;
    & .home-banner_overlay {
      background-color: var(--v-primary-base);
      opacity: .4;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    & .home-banner_content {
      height: 100vh;
      width: 100%;
      position: relative;
      & .home-banner_content_text {
        font-size: 2rem;
      }
    }
  }
  .home-banner-content {
    height: 100vh;
  }

  .text-description {
    font-size: 1.2rem;
    font-weight: 400;
  }
</style>
