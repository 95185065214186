<template>
  <div>
    <v-form @submit.prevent="loginFormHandler">
      <h2 class="text-center">Login</h2>
      <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
      <v-text-field outlined name="username" label="Email" v-model="username"></v-text-field>
      <v-text-field outlined name="password" label="Password" type="password"  v-model="password"></v-text-field>
      <v-row class="mb-2" justify="center">
        <v-btn :loading="loading" type="submit" color="primary">Login</v-btn>
      </v-row>
      <v-row justify="center">
        <router-link class="mx-2" to="/auth/signup">Don't have an account?</router-link> / 
        <router-link class="mx-2" to="/auth/forgot-password">Forgot password?</router-link>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { Auth } from  '@/plugins/amplify';

  export default {
    name: 'Login',
    metaInfo: {
      title: 'Login',
    },
    data: () => ({
        errorMessage: '',
        username: '',
        password: '',
        loading: false,
    }),
    methods: {
        ...mapActions({ setUser: 'user/setInfo' }),
        async loginFormHandler() {
            console.log('login');
            this.errorMessage = '';
            this.loading = true;
            try {
                const user = await Auth.signIn(this.username, this.password);
                console.log("🚀 ~ file: Login.vue:33 ~ loginFormHandler ~ user:", user);
                this.setUser(user);
                const query = this.$route.query;
                console.log(query);
                console.log(this.$route);
                this.$router.push('/dashboard');
            }
            catch (error) {
                console.log('Error', error);
                this.errorMessage = error.message;
            }
            finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>