export default {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // (required)- Amazon Cognito Region
    region: process.env.VUE_APP_COGNITO_REGION,

    // (optional) - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    signUpVerificationMethod: 'code',
    // (optional) - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    //   cookieStorage: {
    //     // - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    //     // (optional) - Cookie path
    //     path: '/',
    //     // (optional) - Cookie expiration in days
    //     expires: 365,
    //     // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite: 'strict' | 'lax',
    //     // (optional) - Cookie secure flag
    //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    //   },

    // (optional) - customized storage object
    //   storage: MyStorage,

    // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { myCustomKey: 'myCustomValue' },

    // (optional) - Hosted UI configuration
    oauth: {
      domain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
      scope: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user'
      ],
      redirectSignIn: 'http://localhost:8080/',
      redirectSignOut: 'http://localhost:8080/',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  API: {
    endpoints: [
      {
          name: 'base',
          endpoint: process.env.VUE_APP_API_BASE_URL
      }
    ]
  }
}