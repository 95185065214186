<template>
    <v-snackbar v-model="statusMessageShow" :color="statusMessageType" top right>
        {{ statusMessage }}
        <!--<template v-slot:action="{ attrs }">
            <v-btn
                    icon
                    v-bind="attrs"
                    @click="statusMessageShow = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>-->
    </v-snackbar>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'StatusMessage',
    data() {
      return {
      }
    },
    computed: mapState({
      statusMessage: state => state.statusMessage,
      statusMessageShow: state => state.statusMessageShow,
      statusMessageType: state => state.statusMessageType
    })
  }
</script>

<style scoped>

</style>
