import axios from 'axios';

const send = async (data) => {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/contact`, data);
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export default {
  send
}