<template>
    <div>
        <v-app-bar color="#f6f6f6">
            <router-link to="/" class="d-flex align-center text-decoration-none">
                <v-img
                        alt="My Milestone logo"
                        class="shrink mr-2"
                        contain
                        src="@/assets/logo.svg"
                        transition="scale-transition"
                        width="30"
                />
                <h2 class="black--text">My Milestone<small class="grey--text">.org</small></h2>
            </router-link>
            <v-spacer></v-spacer>
            <span class="mr-1">Hello, {{ userInfo.name }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item to="/auth/logout">
                  <v-list-item-title>Sign out <v-icon>mdi-exit-run</v-icon></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-app-bar>

        <v-main class="py-4 mb-16">
            <router-view :key="$route.path"></router-view>
        </v-main>

        <v-footer padless absolute>
            <v-col
                    class="text-center"
                    cols="12"
            >
                © 2022 - {{ new Date().getFullYear() }} — <strong>my-milestone.org</strong> created by <a target="_blank" href="https://www.linkedin.com/in/leonid-serdiuk-dev">Leonid Serdiuk</a>
            </v-col>
        </v-footer>
    </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'MainLayout',
    computed: mapState({
      userInfo: state => state.user.info
    }),
    data: () => ({
    }),
  }
</script>

<style scoped>

</style>
