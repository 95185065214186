import Vue from 'vue'
import Vuex from 'vuex'
import user from './user';
import goal from './goal';
import goalItem from './goalItem';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    statusMessage: '',
    statusMessageShow: false,
    statusMessageType: 'success'
  },
  mutations: {
    setStatusMessage(state, data) {
      if (!data.message) {
        state.statusMessageShow = false
      } else {
        if (data.type && ['success', 'info', 'warning', 'error'].includes(data.type)) {
          state.statusMessageType = data.type;
        } else {
          state.statusMessageType = 'success';
        }
        state.statusMessage = data.message;
        state.statusMessageShow = true;
        setTimeout(() => {
          state.statusMessageShow = false;
        }, data.timeout || 3000);
      }
    }
  },
  actions: {
    statusMessage({commit}, data) {
      commit('setStatusMessage', data);
    }
  },
  modules: {
    user,
    goal,
    goalItem
  }
});
