<template>
    <v-card>
        <v-card-title class="subheading font-weight-bold">
            <v-row>
                <v-col cols="9" sm="9">{{ goalData.name }}</v-col>
                <v-col cols="3" sm="3" class="text-center text-sm-right">
                    <v-btn icon color="info" @click="expandActionHandler('edit')"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                    <v-dialog
                            v-model="removeConfirmationDialog"
                            width="450"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  v-bind="attrs"
                                    v-on="on" icon color="error"><v-icon>mdi-delete-outline</v-icon></v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5 grey lighten-3" dark>
                                <h6>Attention!</h6>
                            </v-card-title>
                            <v-card-text class="py-3">
                                If you press "Yes" the goal will be permanently removed. There is no chance to restore removed role.<br>
                                Are you sure you want to permanently remove goal <strong>{{editGoal.name}}</strong>?
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="primary"
                                        text
                                        @click="removeConfirmationDialog = false"
                                >
                                    No
                                </v-btn>
                                <v-btn  text
                                        :loading="removeGoalLoading"
                                        color="error"
                                        @click="deleteGoalHandler"
                                >
                                    Yes
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-row justify="space-between" align="center">
                <v-col cols="12" xl="4" lg="4" md="4">
                    {{ goalData.description }}
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6">
                    <v-progress-linear
                            height="20"
                            :value="progress"
                            stream
                            color="primary lighten-3"
                            rounded
                    >
                        <v-row justify="space-between">
                            <v-col class="text-left mx-2"><strong>{{goalData.prefix}}{{goalData.startValue}}{{goalData.suffix}}</strong></v-col>
                            <v-col class="text-center mx-2"><strong>{{goalData.prefix}}{{goalData.currentValue}}{{goalData.suffix}}</strong></v-col>
                            <v-col class="text-right mx-2"><strong>{{goalData.prefix}}{{goalData.targetValue}}{{goalData.suffix}}</strong></v-col>
                        </v-row>
                    </v-progress-linear>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2" class="text-center text-md-right">
                    <v-btn icon color="success" @click="expandActionHandler('newItem')"><v-icon>mdi-file-document-plus-outline</v-icon></v-btn>
                    <v-btn icon color="info" @click="expandActionHandler('items')"><v-icon>mdi-file-document-outline</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text v-if="isExpanded">
            <div class="text-center" v-if="getItemsLoading">
                <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </div>
            <div class="text-left" v-if="!getItemsLoading && innerContentType === 'items'">
                <div v-if="goalItems.length === 0" class="text-center"><em>There is no items for this goal yet.</em></div>
                <v-timeline v-else align-top dense>
                    <v-timeline-item small v-for="goalItem in goalItems" :key="`gi_${goalItem.sk}`">
                        <strong>{{ toHumanDate(goalItem.timestamp) }}:</strong> {{goalData.prefix}}{{ goalItem.value }}{{goalData.suffix}}
                        <div v-if="goalItem.comment">{{ goalItem.comment }}</div>
                    </v-timeline-item>
                </v-timeline>
            </div>
            <div class="text-left" v-if="innerContentType === 'edit'">
                <v-row justify="center">
                    <v-col cols="12" xl="4" lg="6">
                        <v-form ref="editGoalForm" v-model="editGoalFormValid"
                                @submit.prevent="editGoalHandler"
                                lazy-validation
                        >
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            solo
                                            flat
                                            v-model="editGoal.name"
                                            :counter="50"
                                            label="Name"
                                            hint="Required"
                                            outlined
                                            :rules="editGoalFormRules.name"
                                            placeholder="Pay loan for BMW M3"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                            solo
                                            flat
                                            v-model="editGoal.description"
                                            name="description"
                                            :rules="editGoalFormRules.description"
                                            :counter="200"
                                            label="Description"
                                            outlined
                                            placeholder="Pay at least $3000 every month for a year"
                                            rows="3"
                                            hint="Optional"
                                    ></v-textarea>
                                </v-col>
                                <v-col class="text-center">
                                    <v-btn :loading="editGoalLoading" color="primary" type="submit">Update</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
            <div class="text-left" v-if="innerContentType === 'newItem'">
                <div class="text-center" v-if="newGoalItemLoading">
                    <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </div>
                <v-row v-else justify="center">
                    <v-col cols="12" lg="8">
                        <v-form ref="newGoalItemForm" v-model="newGoalItemFormValid"
                                @submit.prevent="addGoalItemHandler"
                                lazy-validation
                        >
                            <v-row>
                                <v-col cols="12" sm="4" md="4" lg="3" xl="2">
                                    <v-text-field
                                            solo
                                            flat
                                            v-model.number="newGoalItem.value"
                                            label="Value"
                                            placeholder="3000"
                                            :rules="newGoalItemFormRules.numberValue"
                                            type="number"
                                            hint="Required"
                                            outlined
                                            required
                                            dense
                                    >
                                        <span slot="prepend-inner">{{goalData.prefix}}</span>
                                        <span slot="append">{{goalData.suffix}}</span>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" lg="7" xl="8">
                                    <v-text-field
                                            solo
                                            flat
                                            dense
                                            v-model="newGoalItem.comment"
                                            name="description"
                                            :rules="newGoalItemFormRules.comment"
                                            :counter="100"
                                            label="Comment"
                                            outlined
                                            placeholder="Payment for July"
                                            rows="2"
                                            hint="Optional"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-btn color="primary" block style="height: 40px" type="submit">Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { DateTime } from 'luxon';

  export default {
    props: ['goal-data', 'is-expanded', 'expand-action'],
    name: "Goal",
    data() {
      return {
        innerContentType: 'items',
        removeGoalLoading: false,
        removeConfirmationDialog: false,
        editGoal: {},
        editGoalLoading: false,
        editGoalFormValid: true,
        editGoalFormRules: {
          name: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 50) || 'Name must be less than 50 characters',
          ],
          description: [
            v => (!v || (v && v.length <= 200)) || 'Description must be less than 200 characters',
          ],
        },
        getItemsLoading: false,
        newGoalItemLoading: false,
        newGoalItem: {},
        newGoalItemFormValid: true,
        newGoalItemFormRules: {
          numberValue: [
            v => typeof v === 'number' || 'Field is required',
            v => (typeof v === 'number' && /^\d+$/.test(v)) || 'Target must be a number',
          ],
          comment: [
            v => (!v || (v && v.length <= 100)) || 'Comment must be less than 200 characters',
          ],
        }
      }
    },
    mounted() {
      this.setEditGoal();
    },
    watch: {
      goalData() {
        this.setEditGoal();
      }
    },
    computed: {
      ...mapState({
        goalItems: (state) => state.goalItem.list,
      }),
      progress () {
        let totalDiff = this.goalData.targetValue - this.goalData.startValue;
        totalDiff = totalDiff < 0 ? totalDiff * -1 : totalDiff;
        let currentDiff = this.goalData.targetValue - this.goalData.currentValue;
        const fromSmallToBig = this.goalData.startValue < this.goalData.targetValue;
        currentDiff = currentDiff < 0 && !fromSmallToBig ? currentDiff * -1 : currentDiff;
        return Math.round((totalDiff - currentDiff) / totalDiff * 10000) / 100;
      },
    },
    methods: {
      ...mapActions({
        createGoalItem: 'goalItem/create',
        getAllGoalItems: 'goalItem/read',
        updateGoal: 'goal/update',
        removeGoal: 'goal/remove',
      }),
      setEditGoal() {
        this.editGoal = {
          sk: this.goalData.sk,
          name: this.goalData.name,
          description: this.goalData.description,
          timestamp: this.goalData.timestamp,
        }
      },
      toHumanDate(timestamp) {
        const time = DateTime.fromSeconds(timestamp);

        return time.toISODate();
      },
      async expandActionHandler(type) {
        let expandValue = !this.isExpanded;
        if (this.innerContentType !== type) {
          this.innerContentType = type;
          expandValue = true;
        }
        this.expandAction(expandValue);
        if (expandValue && type === 'items') {
          await this.getGoalItems();
        }
      },
      async getGoalItems() {
        // start loader
        this.getItemsLoading = true;
        try {
          await this.getAllGoalItems({goalTimestamp: this.goalData.timestamp});
        } catch (error) {
          console.log(error);
          this.$store.dispatch('statusMessage', {message: 'Ooops... Something went wrong. Try again later', type: 'error'});
        } finally {
          // stop loader
          this.getItemsLoading = false;
        }
      },
      async editGoalHandler() {
        this.editGoalLoading = true;
        try {
          await this.updateGoal(this.editGoal);
          this.expandAction(false);
          this.$store.dispatch('statusMessage', {message: 'Goal updated'});
        } catch (error) {
          console.error(error);
          this.$store.dispatch('statusMessage', {message: 'Ooops... Something went wrong. Try again later', type: 'error'});
        } finally {
          // stop loader
          this.editGoalLoading = false;
        }
      },
      async deleteGoalHandler() {
        this.removeGoalLoading = true;
        try {
          await this.removeGoal(this.editGoal);
          this.$store.dispatch('statusMessage', {message: 'Goal removed'});
        } catch (error) {
          console.error(error);
          this.$store.dispatch('statusMessage', {message: 'Ooops... Something went wrong. Try again later', type: 'error'});
        } finally {
          // stop loader
          this.removeGoalLoading = false;
          this.removeConfirmationDialog = false
          // hide dialog
        }
      },
      async addGoalItemHandler() {
        // start loader
        this.newGoalItemLoading = true;
        try {
          await this.createGoalItem({goalTimestamp: this.goalData.timestamp, ...this.newGoalItem});
          // clear form
          this.newGoalItem = {};
          // switch to goal items list inner section
          this.innerContentType = 'items';
        } catch (error) {
          console.error(error);
          this.$store.dispatch('statusMessage', {message: 'Ooops... Something went wrong. Try again later', type: 'error'});
        } finally {
          // stop preloader
          this.newGoalItemLoading = false;
        }
      }
    }
  }
</script>

<style scoped>

</style>
